<template>
  <div class="cdn-config">
    <transition name="fade" mode="out-in">
      <base-loader v-if="!configurator.length" />
      <main-card v-else>
        <div class="cdn-config-config">
          <component
            :is="componentsByType[item.type]"
            v-for="item in configurator"
            :key="item.intname"
            v-bind="item"
            class="cdn-config-config__item"
            @change="onChange(item, $event)"
          />
        </div>
        <template #footerEnd>
          <div class="cdn-config__footer">
            <div class="cdn-config__summary medium-title">
              <span class="cdn-config__summary-text"> {{ $t('summary') }}: </span>
              <span class="cdn-config__summary-sum">
                {{
                  (sum > 0 ? '+' : '') +
                  `${$n(sum, 'currency')} / ${$tc('period.month_c', tariff.period)}`
                }}
              </span>
            </div>
            <div class="cdn-config__order-btns">
              <base-button
                :disabled="
                  !hasChanges ||
                  status === 4 ||
                  status === 5 ||
                  tariff.in_pay === 'on' ||
                  isSendingToBasket
                "
                :loading="isSending"
                class="cdn-config__order-btn"
                @click="sum > 0 ? sendToPay() : save()"
              >
                {{ status === 1 || 0 >= sum ? $t('save') : $t('pay') }}
              </base-button>
              <base-button
                v-if="status !== 1 && sum > 0"
                :disabled="
                  !hasChanges || status === 4 || status === 5 || tariff.in_pay === 'on' || isSending
                "
                :loading="isSendingToBasket"
                theme="outlined"
                class="cdn-config__order-btn"
                @click="sendToBasket()"
              >
                {{ $t('basket') }}
              </base-button>
            </div>
          </div>
        </template>
      </main-card>
    </transition>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard.vue';
import SliderBlock from '@/components/Configurator/components/SliderBlock.vue';
import TextBlock from '@/components/Configurator/components/TextBlock.vue';
import SelectBlock from '@/components/Configurator/components/SelectBlock.vue';
import { throttle } from 'lodash';
import { CDNTariff } from '@/models/BillMgr/CDNTariff';
import { isEqual, isNaN } from 'lodash';
import showErrorModal from '@/mixins/showErrorModal';
import wizardPay from '@/mixins/billmgr/wizardPay';
import handleRedirect from '@/mixins/billing/handleRedirect';
import qs from 'qs';
import PaymentMethodConfigurator from '@/models/BillMgr/PaymentMethodConfigurator';
export default {
  name: 'CDNConfig',
  components: { MainCard, SliderBlock, TextBlock, SelectBlock },
  mixins: [showErrorModal, wizardPay, handleRedirect],
  props: {
    tariff: {
      type: CDNTariff,
      required: true,
      validator: obj => obj instanceof CDNTariff,
    },
  },
  data() {
    return {
      sum: 0,
      componentsByType: {
        slider: SliderBlock,
        text: TextBlock,
        select: SelectBlock,
      },
      isSending: false,
      isSendingToBasket: false,
      startFunc: 'payment.add',
      backup: null,
      formData: null,
      configuratorClass: PaymentMethodConfigurator,
    };
  },
  computed: {
    configurator() {
      return this.tariff.configurator.filter(x => x.label !== 'Выделенные IP адреса');
    },
    hasChanges() {
      return !isEqual(this.backup, this.formData);
    },
    tools() {
      return this.$store.state.moduleCDN.tools;
    },
    profile() {
      return this.$store.state.moduleProfile.profile;
    },
    isEditEnable() {
      return this.tools && this.tools.edit && this.tools.edit.isEnable(this.tariff);
    },
    status() {
      return this.tariff.status.code;
    },
  },
  watch: {
    configurator() {
      if (this.configurator.length) this.init();
    },
  },
  mounted() {
    if (this.configurator.length) this.init();
  },
  methods: {
    init() {
      this.initFormData();
      this.makeBackup();
      this.calcSum();
    },
    makeBackup() {
      this.backup = { ...this.formData };
    },
    initFormData() {
      this.formData = this.configurator.reduce((acc, addon) => {
        const value = !isNaN(parseInt(addon.currentValue))
          ? parseInt(addon.currentValue)
          : addon.currentValue;
        acc[addon.name] = value;
        return acc;
      }, {});
    },
    onChange(item, payload) {
      const { value, sum } = payload;
      item.currentValue = value;
      const intValue = !isNaN(parseInt(value)) ? parseInt(value) : value;
      this.formData[item.name] = intValue;
      if (item.sum !== sum) {
        item.sum = sum;
        throttle(
          () => {
            this.calcSum();
          },
          100,
          { trailing: false }
        )();
      }
    },
    calcSum() {
      this.sum = this.configurator.reduce((sum, i) => {
        sum += parseFloat(i.sum);
        return sum;
      }, 0);
    },
    getFormData() {
      return {
        ...this.formData,
        elid: this.tariff.id,
        account: this.profile.account,
        func: this.tools.edit.func,
      };
    },
    sendToBasket() {
      this.isSendingToBasket = true;
      // this.$gtm.trackEvent({
      //   event: '_event_arrange', // Event type [default = 'interaction'] (Optional)
      //   category: 'CDN',
      //   action: 'click',
      //   label: 'basket',
      //   value: 5000,
      //   noninteraction: false, // Optional
      // });
      const params = {
        clicked_button: 'basket',
        newface: 'on',
      };
      this.save(params, 'basket', 'isSendingToBasket');
    },
    sendToPay() {
      this.isSending = true;
      const params = {
        ...this.getFormData(),
        clicked_button: 'basket',
        newbasket: 'on',
      };
      this.showResFunc.success = false;
      // this.$gtm.trackEvent({
      //   event: '_event_arrange', // Event type [default = 'interaction'] (Optional)
      //   category: 'Birtix',
      //   action: 'click',
      //   label: 'pay',
      //   value: 5000,
      //   noninteraction: false, // Optional
      // });
      this.$store
        .dispatch('moduleCDN/updateDetail', params)
        .then(data => {
          this.$store.dispatch('moduleBasket/fetchBasket');
          if (data && data.ok && data.ok.type && data.ok.type === 'form' && data.ok.v) {
            const { billorder } = qs.parse(data.ok.v);
            this.startParams = { billorder };
            this.runWizardPay()
              .then(async data => {
                const res = await this.handleRedirect(data.ok);
                if (res.ok) {
                  const text =
                    res.func === 'redirect'
                      ? this.$t('success.redirect')
                      : this.$t('success.pay', { num: this.$n(this.sum, 'currency') });
                  this.showSuccessModal(text);
                }
                this.updatePageData();
              })
              .catch(() => {
                const basketItem = this.$store.state.moduleBasket.shadow[billorder][0];
                if (basketItem) {
                  this.$store.dispatch('moduleBasket/removeFromBasket', basketItem).finally(() => {
                    this.$store.dispatch('moduleBasket/fetchBasket');
                  });
                }
              });
          }
        })
        .catch(e => this.showError(e))
        .finally(() => (this.isSending = false));
    },
    save(payload = {}, action = 'update', loader = 'isSending') {
      this[loader] = true;
      const params = {
        ...this.getFormData(),
        ...payload,
      };
      this.$store
        .dispatch('moduleCDN/updateDetail', params)
        .then(data => {
          if (data.ok) {
            this.showSuccessModal(
              this.$t(`success.${action}`, { num: this.$n(this.sum, 'currency') })
            );
            this.updatePageData();
          }
        })
        .catch(e => this.showError(e))
        .finally(() => (this[loader] = false));
    },
    updatePageData() {
      return Promise.all([
        this.$store.dispatch('moduleCDN/fetchDetail', this.tariff.id),
        this.$store.dispatch('moduleProfile/setProfileInfo'),
        this.$store.dispatch('moduleBasket/fetchBasket'),
      ]);
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "summary": "Итого",
    "pay": "Оплатить",
    "basket": "В корзину",
    "save": "Сохранить",
    "success": {
      "basket": "Заказ на {num} успешно добавлен в корзину",
      "pay": "Конфигурация успешно обновлена, {num} списано с лицевого счета",
      "redirect": "Конфигурация успешно обновлена, продолжите оплату и дождитесь обновления данных",
      "update": "Изменения успешно сохранены."
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.cdn-config {
  margin-top: 1.5rem;

  +breakpoint(sm-and-up) {
    margin-top: 2.5rem;
  }

  &__footer {
    flexy(flex-end, flex-end);
    flex-direction: column;

    +breakpoint(sm-and-up) {
      flex-direction: row;
      align-items: center;
      padding-bottom: 1rem;
    }
  }

  &__summary {
    flexy(flex-end, center);
    margin-bottom: 1rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 0;
      margin-right: 2.5rem;
    }

    &-text {
      margin-right: 1.5rem;
    }
    &-sum {
      text-transform: lowercase;
    }
  }

  &__order-btns {
    margin: -0.5rem;
  }

  &__order-btn {
    min-width: 160px;
    margin: 0.5rem;
    width: calc(100% - 1rem);

    +breakpoint(ms-and-up) {
      width: auto;
    }
  }

  &-config {
    +breakpoint(sm-and-up) {
      margin-top: 1rem;
    }

    &__item {
      & + & {
        margin-top: 1.5rem;

        +breakpoint(sm-and-up) {
          margin-top: 2.5rem;
        }
      }
    }
  }
}
</style>
